html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  font: 1.6rem Helvetica;
  height: 100vh;
  margin: 0;
}
button {
  font-size: 1.6rem;
}
.full-width {
  width: 100%;
}
#root {
  height: 100%;
}
.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
}
.header {
  grid-area: header;
  background-color: #131921;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.brand a {
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
  text-decoration: none;
}
.header-links a {
  color: #ffffff;
  text-decoration: none;
  padding: 0.2rem;
}
.header-links a:hover {
  color: #00897b;
}
.main {
  grid-area: main;
}
.footer {
  grid-area: footer;
  background-color: #203040;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
}
a:hover {
  color: #00b6bd;
}
/* Home Screen */
.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 28rem;
}
.products li {
  list-style-type: none;
  padding: 0;
  flex: 0 1 34rem;
  margin: 1rem;
  height: 50rem;
  border-bottom: 0.1rem #c0c0c0 solid;
}
.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.product-name {
  font-size: 2rem;
  font-weight: bold;
}
.product-brand {
  font-size: 1.2rem;
  color: #808080;
}
.product-price {
  font-size: 2rem;
  font-weight: bold;
}
.product-image {
  max-width: 34rem;
  max-height: 34rem;
}
.product-rating {
  margin-bottom: 1rem;
}
/* Reviews */
ul.review {
  list-style-type: none;
  padding: 0;
}
.review li {
  margin: 1rem;
  margin-bottom: 2rem;
}
.review li > div {
  margin: 0.5rem;
}

/* Rating */
.rating span {
  color: #ffb901;
  font-size: 1.8rem;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #444444;
  font-size: 1.4rem;
}
a > .rating > span:last-child {
  color: #0000ff;
}

/* Sidebar */

.brand button {
  font-size: 3rem;
  padding: 0.5rem;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}
.sidebar {
  position: fixed;
  transition: all 0.5s;
  transform: translateX(-30rem);
  width: 30rem;
  background-color: #f0f0f0;
  height: 100%;
}
.sidebar.open {
  transform: translateX(0);
}

.sidebar-close-button {
  border-radius: 50%;
  border: 0.1rem #000000 solid;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  font-size: 2rem;
  padding-top: 0;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 1.5rem;
}

/* Product Details */
.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}
.details-image {
  flex: 2 1 60rem;
}
.details-image img {
  max-width: 45rem;
  width: 100%;
}
.details-info {
  flex: 1 1 30rem;
}
.details-action {
  flex: 1 1 30rem;
}

.details-info ul,
.details-action ul {
  list-style-type: none;
  padding: 0;
}
.details-info li,
.details-action li {
  margin-bottom: 1rem;
}
.back-to-result {
  padding: 1rem;
}
.details-action {
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  padding: 1rem;
  margin: 2rem;
}
.details-action ul li:last-child {
  display: flex;
  flex-direction: column;
}
.button {
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  border: 0.1rem #404040 solid;
}

.button.secondary {
  background-color: #f0f0f0;
}
.text-center {
  text-align: center;
}
/* Cart */

.cart {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  align-items: flex-start;
}
.cart-list {
  flex: 3 1 60rem;
}
.cart-action {
  flex: 1 1 20rem;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  padding: 1rem;
}
.cart-list-container {
  padding: 0;
  list-style-type: none;
  padding: 1rem;
}
.cart-list-container li {
  display: flex;
  justify-content: space-between;

  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem #c0c0c0 solid;
}
.cart-list-container li img {
  max-width: 10rem;
  max-height: 10rem;
}
.cart-list-container li:first-child {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.cart-image {
  flex: 1 1;
}
.cart-name {
  flex: 8 1;
}
.cart-price {
  flex: 1 1;
  font-size: 2.5rem;
  text-align: right;
}

/* forms */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  list-style-type: none;
}
.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
input {
  padding: 1rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
}

/* Products */

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content-margined {
  margin: 1rem;
}

/* .content{
  font-family: 'Montserrat', sans-serif;
} */

.table {
  width: 100%;
}
th {
  text-align: left;
}
tbody > tr:nth-child(odd) {
  background-color: #f0f0f0;
}

/* Checkout Steps */

.checkout-steps {
  display: flex;
  justify-content: space-between;
  width: 40rem;
  margin: 1rem auto;
}
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1 1;
  padding-top: 1rem;
}
.checkout-steps > div.active {
  border-top: 0.3rem #f08000 solid;
  color: #f08000;
}

/* Place Order */

.placeorder {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-between;
}
.placeorder-info {
  flex: 3 1 60rem;
}

.box-shadow {
  border: none !important;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}

.placeorder-action {
  flex: 1 1 20rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1rem;
}
.placeorder-info > div {
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1rem;
  margin: 1rem;
}
.placeorder-info > div:first-child {
  margin-top: 0;
}
.placeorder-action > ul {
  padding: 0;
  list-style-type: none;
}
.placeorder-action > ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.total-price {
  font-size: 2rem;
  font-weight: bold;
  color: #030ad8;
}
.placeorder-actions-payment > div {
  width: 100%;
}

/* Profile */

.profile {
  display: flex;
  flex-wrap: wrap;
}

.profile-info {
  flex: 1 1 30rem;
}
.profile-orders {
  flex: 3 1 60rem;
}

/* admin */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  padding: 1rem;
  list-style-type: none;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}

/* filter */

.filter {
  display: flex;
  list-style-type: none;
  padding-top: 1.8rem;
  margin: 1rem auto;
  max-width: 45rem;
  justify-content: space-between;
  align-items: center;
}
.filter input,
.filter button,
.filter select {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #c0c0c0 solid;
  font-size: 1.6rem;
}
.categories {
  padding: 0;
  list-style-type: none;
}
.categories a {
  display: flex;
  padding: 1rem;
}

.categories a:hover {
  background-color: #c0c0c0;
}

.cart__center {
  align-content: center;
  align-items: center;
}

/*Custom Components*/
.custom-product-list {
  padding: 4rem;
}

.header__logo {
  width: 20rem;
  object-fit: contain;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-top: 2rem;
}

.header__search {
  display: flex;
  flex: 1;
}

.header__searchInput {
  height: 1rem;
  padding: 0.7rem;
  border: none;
  width: 100%;
}

.header_searchIcon {
  background-color: #cd9042;
  padding: 0.5rem;
  height: 1.5rem !important;
}

.header__nav {
  display: flex;
  justify-content: space-evenly;
}

.header__optionLineone {
  font-size: 10px;
}

.header__optionLinetwo {
  font-size: 13px;
  font-weight: 800;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.header__optionBasket {
  display: flex;
  align-content: center;
}

.header__basketCount {
  margin-left: 10px;
  margin-right: 10px;
}

.header__link {
  color: white;
  text-decoration: none;
}

.white__link {
  color: white;
}

.color_black {
  color: black;
}

.color_turqois {
  color: #ffffff;
}

.table-list {
  padding-top: 2rem;
  list-style-type: none;
}

.table-only {
  max-width: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2rem;
  padding-top: 5rem;
}

.loading {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  padding-top: 2.7rem;
  top: 0;
  width: 100%;
  z-index: 100;
}

.list__padding {
  padding-right: 2rem;
}

.home__image {
  width: 100%;
  height: 225px;
  /* mask-image: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,0));
  z-index: -900; */
}

.dropdown {
  /* z-index: 100; */
}

.body {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.bold {
  /* font-weight: bold; */
}

.admin {
  justify-content: center;
}

.custom-button {
  color: #d803d8;
}

.carousel-images {
  width: 100px;
  height: 200px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-body {
  width: 100%;
  height: 37%;
  padding-top: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
}

.banner {
  width: 100%;
  height: 30%;
  padding: 2rem;
  position: absolute;
}

.not-found {
  color: #000000;
}

.screen-content {
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 2rem;
  padding-top: 5rem;
}

.recommendation {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.main-container {
  padding: 4% 12%;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.product-preview {
  width: 50%;
}

.product-preview > img {
  width: 100%;
}

.product-data {
  width: 50%;
}

.product-title {
  font-size: 48px;
  font-weight: 600;
  flex: 1 1 30rem;
}

.webcam {
  padding-top: "20px";
  height: 20%;
}

/*Modal CSS*/
.modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content-wrapper {
  position: fixed;
  width: 300px;
  height: 300px;
  background: white;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
}

.modalText {
  padding-left: 20px;
}
